/* Login.css */
.login-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.login-form {
	width: 300px;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: var(--background);
	margin-bottom: 20%;
}

.login-form form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.login-title {
	font-size: 24px;
	margin-bottom: 20px;
	text-align: center;
}

.login-title > span {
	font-family: Georgia, 'Times New Roman', Times, serif;
	font-family: var(--titleFont);
	display: inline-block;
	font-size: 30px;
	color: var(--primary);
	/* font-weight: bold; */
}

.login-input {
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	border-radius: 3px;
}

.login-button {
	width: 100%;
	padding: 10px;
	background-color: var(--primary);
	color: var(--background);
	border: none;
	border-radius: 3px;
	cursor: pointer;
}

.return-to-login .login-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.login-button:hover {
	background-color: var(--secondary);
}

.login-logo {
	height: 35px;
}

.login-container div.error-message {
	color: rgb(187, 0, 0);
	font-size: 12px;
}

.signup-p {
	text-align: center;
	margin: auto;
	margin-top: 15px;
}

.signup-p > span {
	color: var(--accent);
	text-decoration: underline;
	transition: all .2s;
}

.signup-p > span:hover {
	color: var(--secondary);
	text-decoration: none;
	cursor: pointer;
}


.signup-container {
	width: 100vw;
	height: 100vh;
	padding-bottom: 100px;
	box-sizing: border-box;

	display: flex;
	justify-content: center;
	align-items: center;
}

.add-user-form {
	background-color: var(--background);
	display: flex;
	flex-direction: column;

	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border: 1px solid #ccc;

	width: 340px;
	margin: auto;
}

.add-user-form > div {
	padding: 7px;
}

.add-user-form > div.input-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.add-user-form > div.input-container > input {
	width: 70%;
}

.add-user-form > div.button-container {
	margin: auto;
	margin-top: 15px;
}

.add-user-form > div.button-container > button {
	margin: 0 10px 0 10px;
	padding: 10px 15px 10px 15px;
	opacity: 1;
}

.add-user-form > div.button-container > button:hover {
	opacity: .8;
}

.add-user-form > div > .submit-button {
	background-color: var(--primary);
	color: var(--background);
}

.add-user-form > div > .cancel-button {
	background-color: var(--accent);
	color: var(--background);
}