details > summary {
	font-weight: bold;
	text-align: left;
	list-style: none;
	padding-left: 10px;
}

.company-display-table {
	text-align: left;
}

.company-display-table > h2 {
	text-align: center;
}

.company-display-table > button {
	margin: 20px;
}

.admin-details, .employee-details {
	margin-left: 20px;
}

.admin-details > p, .employee-details > p {
	margin: 5px;
	margin-left: 20px;
}

.company-display-table .company-logo {
	margin: auto;
	max-width: 300px;
}

.company-display-table > ul {
	padding: 0;
	margin: 0;
}

.company-display-table > ul > li {
	list-style-type: none;
	display: flex;
	flex-direction: row;
	width: 100%;
}

.company-display-table > ul > li span {
	/* border: 3px solid black; */
	display: inline-block;
	padding-top: 5px;
	padding-bottom: 5px;

	border-collapse: collapse;
	box-sizing: border-box;

	/* border: 1px solid var(--text); */
}

span.employee-name {
	width: 70%;
	/* background-color: rgb(228, 255, 255); */
	text-align: left;
	padding-left: 10px;
}
span.employee-weekly-hours {
	width: 25%;
	background-color: var(--secondary);
	text-align: center;
}

/* this grabs the react-spinner */
span.employee-weekly-hours > span {
	margin: auto;
}

.company-display-table .dropdown {
	width: 5%;

	padding: 0;
	margin: 0;
}

.company-display-table .kebab-container {
	width: 100%;
	height: 100%;

	padding: 0;
	margin: 0;

	display: inline-flex;
	align-items: center;
	justify-content: right;
	
	color: var(--text);
	background-color: var(--background);

	text-decoration: none;
}

.company-display-table span.kebab {
	font-weight: bold;
	text-align: center;

	/* font-size: 23px; */
	/* padding: 0px 2px; */
	/* border-radius: 5px; */
	margin: 0;
	z-index: 0;
	/* text-shadow: 0px 0px 3px black; */
	/* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
}

.company-display-table li.table-key {
	border-bottom: 3px solid black;
	margin-top: 40px;
}

.company-display-table li.table-key > .date-row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.company-display-table li.table-key > .date-row > .week-button {
	border: none;
	border-radius: 3px;
	padding: 0;
	/* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
	/* text-shadow: 0 2px 4px rgba(0, 0, 0);
	filter: drop-shadow(0px 2px 4px rgb(0 0 0 / 0.2));  */
	color: var(--text);
	font-size: 32px;
}
.company-display-table li.table-key > .date-row > .week-button:hover {
	color: var(--accent);
	cursor: pointer;
}

.company-display-table li.table-key > .date-row span.week-string {
	font-size: 20px;
}

.company-display-table li div.more-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: min-content;
}

.company-display-table li div.more-info > button.toggler {
	background-color: var(--accent);
	border-radius: 3px;
	
	width: 100%;
	padding: 10px;
	margin-top: -15px; /* This compensates for HourAdder's own margin-bottom */

	color: var(--background);
	font-weight: bold;
}

.display-table > ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.display-table > ul > li {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	margin-bottom: 20px;
}

.display-table > ul > li .company-display-table > h2 {
	display: none;
}

/** Skeleton Styling **/
.dashboard-content.skeleton {
	padding: 0;
}

.company-display-table.skeleton {
	position: relative;
	box-sizing: border-box;
	padding: 20px;
	margin: 0;
	/* background-color: blue; */
	overflow: hidden;
	background-color: var(--backgroundThree);
	width: 100%;
}

.company-display-table.skeleton .shimmer-box {
	/* background-color: red; */
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;

	z-index: 15;

	animation: shimmer 2s infinite;
}

.company-display-table.skeleton h2 {
	min-height: 80px;
}

.company-display-table.skeleton li.table-key {
	border-bottom: 3px solid black;
}

.company-display-table.skeleton li {
	min-height: 30px;
	box-sizing: border-box;
	border-collapse: collapse;
	border-bottom: 1px solid var(--backgroundTwo);

}

.company-display-table.skeleton span.kebab {
	width: 5%;

	padding: 0;
	margin: 0;
}


@keyframes shimmer {
	0% {
		background-image: linear-gradient(90deg, #ffffff00 0%, #ffffff40 20%, #ffffff80 60%, #ffffff00 100%);
		transform: translateX(-100%);
	}
	100% {
		background-image: linear-gradient(90deg, #ffffff00 0%, #ffffff40 20%, #ffffff80 60%, #ffffff00 100%);
		transform: translateX(100%);
	}
}