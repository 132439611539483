.hour-adder-content {
	background-color: var(--background);
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	width: 320px;
	text-align: center;

	margin-bottom: 20px;
}

.date-picker-label {
	display: block;
	margin-bottom: 10px;
}

.hours-controls {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;

	margin-left: 30px
}

.hours-button {
	background-color: var(--primary);
	color: var(--background);
	border: none;
	border-radius: 10%;
	width: 50px;
	height: 50px;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-bottom: 10px;
}

.preset-buttons {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.worked-hours-container {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	-webkit-user-select: none; /* Safari */
	user-select: none;
}

.hours-and-picker-container p.weekly-total {
	font-size: 10px;
	margin: 0;
	padding: 0;
	margin-bottom: 10px;
}

.hours-and-picker-container p.worked-hours {
	padding: 0;
	margin: 0;
	font-weight: bold;
	font-size: 25px;
}

.hours-and-picker-container p.worked-hours-label {
	padding: 0;
	margin: 0;
	font-weight: bold;
}

.killScroll {
	user-select: none;
	width: 50%;
	margin: auto;

	background-color: var(--backgroundThree);
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	touch-action: none;
}

.add-hours-button-container {
	position: relative;
	margin: 25px 0px 5px 0px;
}

.add-hours-button {
	padding: 6px 12px;
	background-color: var(--primary);
	color: var(--background);
	border: none;
	border-radius: 3px;
	cursor: pointer;
}

.add-notes-button {
	position: absolute;
	right: 0;
	top: 0;
	margin: 0;
	padding: 0;
	background-color: var(--background);
	color: var(--text);
	border: none;
	cursor: pointer;
	font-size: 20px;
}

form.add-notes-form {
	background-color: var(--background);
	display: flex;
	flex-direction: column;

	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

	width: 340px;
}

form.add-notes-form > textarea.notes-input {
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	border-radius: 3px;

	height: 60vh;
	resize: none;

	background-attachment: local;
	background-image:
		linear-gradient(to right, white 10px, transparent 10px),
		linear-gradient(to left, white 10px, transparent 10px),
		repeating-linear-gradient(white, white 30px, #ccc 30px, #ccc 31px, white 31px);
	line-height: 31px;
	padding: 8px 10px;
}

form.add-notes-form > div.button-container > button {
	margin: 0 10px 0 10px;
	padding: 5px 10px 5px 10px;
	border-radius: 3px;

	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

form.add-notes-form > div > .submit-button {
	background-color: var(--primary);
	color: var(--background);
}

form.add-notes-form > div > .cancel-button {
	background-color: var(--accent);
	color: var(--background);
}