div.blocker {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: #00000055;

	animation: appear 1s ease 0s; /* shouldn't even notice it on a fast connection */

	display: flex;
	justify-content: center;
	align-items: center;

	top: 0;
	left: 0;

	overflow: hidden;
}

div.blocker.fast {
	animation-duration: .2s;
}

div.blocker.fast > div.childContainer {
	position: fixed;
	width: 100%;
	height: 100%;
	opacity: 1;
	padding: 50px 0px;

	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;

	animation: riseUp .5s ease 0s;
	
	top: 0;
	left: 0;

	overflow-y: scroll;
}

div.blocker.loading {
	cursor: progress;
}

.contain-click-blocker {
	position: relative;
}

.confirm-container {
	background-color: var(--background);
	display: flex;
	flex-direction: column;
	width: 300px;
	padding: 20px 10px;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
}

.confirm-container .confirm-message {
	text-align: center;
	padding: 0;
	margin: 0;
}

.confirm-container .confirm-message-emphasized {
	text-align: center;
	font-weight: bold;
	padding: 0px 4px;
	border-radius: 3px;
	margin: 0;
	margin-top: 10px;
	color: var(--bs-danger-text-emphasis);
}

.confirm-container .button-container {
	margin-top: 50px;
}

.confirm-container .button-container button {
	border-radius: 5px;
	margin: 0px 8px;
	padding: 5px 7px;
	font-weight: bold;
	color: var(--background);
}
.confirm-container .button-container button.confirm {
	background-color: var(--bs-danger);
}
.confirm-container .button-container button.cancel {
	/* background-color: var(--accent); */
	color: var(--text);
}

@keyframes appear {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes riseUp {
	0% { top: 100vh; }
	100% { top: 0; }
}